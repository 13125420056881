import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filesize",
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, extension: string = "KB") {
    return (size / 1024).toFixed(2) + extension;
  }

  getFileSize(size: number): number {
    return parseInt((size / 1024).toFixed(2));
  }
}