import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import swal from 'sweetalert2';

import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { LoginService } from '../login/login.service';
@Injectable({
  providedIn: 'root',

})
export class SettingsService {
  public access_token: any;
  public branchId: any;
  public subBranchId: any;
  public editBranchId: any;
  public branchBaseUrl: any;
  public marketObjectData:any;
  private headers = new Headers({ 'content-type': 'application/json' });
  private options = new RequestOptions({ headers: this.headers });
  public access_token_asw;
  constructor(private http: Http, private loginService: LoginService, private commonservice: AppService) {

    this.access_token = loginService.access_tokenMethod();
    this.access_token_asw = loginService.accessTokenAsw()
  }

  branchPort() {
    return this.branchBaseUrl = this.loginService.urlNumber() + ":" + this.loginService.portnumber() + "/mff/api";

    //  return this.profileBaseUrl="http://188.214.128.73:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl="http://188.214.128.73:6000/mff/api";
    // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
    //return this.branchBaseUrl = "http://localhost:6003/mff/api";
    /// return this.profileBaseUrl="http://localhost:5151/MFFService";
  }
  getBranch() {
    return this.http.get(this.branchPort() + '/provideBranch?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getSubBranch() {
    // // console.log("serv branchId: "+this.subBranchId);
    return this.http.get(this.branchPort() + '/branches/provideSubBranch/' + this.subBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  updateBranch(editBranchId, editBranchName: any) {
    // // console.log('id: '+editBranchId+" json: "+JSON.stringify(editBranchName));
    return this.http.put(this.branchPort() + '/updateBranch/' + editBranchId + '?access_token=' + this.access_token, editBranchName, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  createBranch(newBranchName: any) {
    // // console.log('new json: '+JSON.stringify(newBranchName));
    return this.http.post(this.branchPort() + '/createBranch?access_token=' + this.access_token, newBranchName, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  delBranch(delBranchId) {
    return this.http.delete(this.branchPort() + '/deleteBranchById/' + delBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  // SUB BRANCH SERVICES
  updateSubBranch(editSubBranchId, editSubBranchData: any) {
    // // console.log('id: '+editSubBranchId+" json: "+JSON.stringify(editSubBranchData));
    return this.http.put(this.branchPort() + '/updateSubBranch/' + editSubBranchId + '?access_token=' + this.access_token, editSubBranchData, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  createSubBranch(newSubBranchData: any) {
    // // console.log('new json: '+JSON.stringify(newSubBranchData));
    return this.http.post(this.branchPort() + '/createSubBranch?access_token=' + this.access_token, newSubBranchData, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  delSubBranch(delSubBranchId) {
    return this.http.delete(this.branchPort() + '/deleteSubBranch/' + delSubBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getAllMarketProviders() {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/providers?access_token=' + this.access_token).map((response: Response) => response.json()['data'])
      .catch(this.errorHandler);
  }

  getAllMarketObjects() {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/objects?access_token=' + this.access_token).map((response: Response) => response.json()['data'])
      .catch(this.errorHandler);
  }

  getOneLatestMarketObjects(marketObjectId) {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/objects/' + marketObjectId + '/values/last?access_token=' + this.loginService.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getMarketObjectsBetweenDates(marketObjectId,from,to) {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/objects/' + marketObjectId + '/values/from/'+from+'/to/'+to+'/by/undefined?access_token=' + this.loginService.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getMarketObjectHistory(marketObjectId,from,to) {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/object/history/' + marketObjectId + '/values/from/'+from+'/to/'+to+'?access_token=' + this.loginService.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getMarketObjectsBetweenDates1(marketObjectId,from,to) {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/objects/' + marketObjectId + '/values/from/'+from+'/to/'+to+'/by/daily?access_token=' + this.loginService.access_token).map((response: Response) => response.json()['data'])
      .catch(this.errorHandler);
  }

  getOneMarketObjects(marketObjectId) {
    return this.http.get(this.loginService.MARKET_OBJECT_URL + '/market/objects/' + marketObjectId + '/details?access_token=' + this.access_token).map((response: Response) => response.json()['data'])
      .catch(this.errorHandler);
  }

  updateMarketObjects(marketObjectId,paylaod) {
    return this.http.put(this.loginService.MARKET_OBJECT_URL + '/market/objects/' + marketObjectId +'?access_token=' + this.access_token,paylaod).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }


  createMarketObject(marketObjectpayload) {
    return this.http.post(this.loginService.MARKET_OBJECT_URL + '/market/objects?access_token=' + this.access_token, marketObjectpayload).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }


  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message) {
      swal({
        title: `<h3 class="swal-msg-font">Sorry!</h3>`,
        html: `<h4 class="swal-msg-font">${errorMessage.message}</h4>`,
        showCancelButton: false,
        showCloseButton:true,
        focusConfirm: true,
        showConfirmButton:false,
        type: 'error'
      })
    }
    return Observable.throw(error || " server error ");
  }
}
