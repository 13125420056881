import { Injectable } from '@angular/core';
import {Http, Response, Headers, RequestOptions} from '@angular/http';
import 'rxjs/add/operator/map'; 
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { AppService } from '../app.service';
import swal from 'sweetalert2';



@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
// public mffSycBaseUrl="http://localhost:6007/mff/sync/api";
public mffSycBaseUrl="http://188.214.128.73:6007/mff/sync/api";
private headers = new Headers ({'content-type':'application/json'});
  private options = new RequestOptions ({headers: this.headers});
  public access_token: any;
  public count :number = 0;
  public portfolioUrl: any;
   portfolioport() {
    //  return this.portfolioUrl=this.loginService.urlNumber()+":"+this.loginService.portnumber()+"/mff/api";
    return this.portfolioUrl=this.loginService.urlNumber()+":"+this.loginService.portnumber()+"/PortfolioService";
    
  }
  constructor(  private http: Http,private loginService: LoginService,private commonservice: AppService,) {
    this.access_token = loginService.access_tokenMethod();
   }

 
  getActiveSummary(){
    return this.http.get(this.commonservice.collectionsURL + '/dailySummary?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }
  getProfiles(){
    return this.http.get(this.commonservice.collectionsURL + '/profiles?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }
  getPortpolioRepayment(){
    return this.http.get(this.portfolioport() + '/getAllPortfolioRepayments?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }

  getPortpolioRepayment1(pageNumber,noOfRecords,userID,hierarchyId){
    return this.http.get(this.portfolioport() + '/tellerReport?access_token=' + this.access_token+(pageNumber?'&pageNumber='+pageNumber:'')+(noOfRecords?'&numberOfRecords='+noOfRecords:'')+(userID?'&userId='+userID:'&userId=')+(hierarchyId?'&Hierarchy='+hierarchyId:'&Hierarchy='), this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }


  getRepaymentReport(filterData,pageNumber,noOfRecords,userID,hierarchyId){
    return this.http.get(this.portfolioport() + '/tellerReport?access_token=' + this.access_token+(pageNumber?'&pageNumber='+pageNumber:'')+(noOfRecords?'&numberOfRecords='+noOfRecords:'')+(userID?'&userId='+userID:'')+(hierarchyId?'&hierarchy='+hierarchyId:'')+(filterData.Name?'&name='+filterData.Name:'')+(filterData.NationalID?'&nationalId='+filterData.NationalID:'')+(filterData.Identifier?'&identifier='+filterData.Identifier:''), this.options).map((response: Response) =>{ return response.json()})
    .catch(this.errorHandler);
  }


  reverseEntry(pRIDdata:any){
    return this.http.put(this.portfolioport() + '/deleteRepaymentDetailsByrepaymentId?access_token=' + this.access_token,pRIDdata, this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }
  getPortpolioRepaymentByTime(repaymentTime){
    return this.http.get(this.portfolioport() + '/getAllPortfolioRepayments/'+repaymentTime+'?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
    .catch(this.errorHandler);
  }

  approveRepayment(id,payload){
      return this.http.put(this.portfolioport() + '/Repayments/' + id + '?access_token=' + this.access_token,payload, this.options)
        .map((res: Response) => res.text()).catch(this.errorHandler);    
  }
  
  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    console.log("errorMessage:"+JSON.stringify(errorMessage));
  
    if(errorMessage.status == 400){
      swal("Sorry!", errorMessage.message, "error");
    
    }
    else if(errorMessage.status == 404){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.status == 401){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.message=="Access is Denied" || errorMessage.message=="Access is denied"){
      swal("Sorry!", errorMessage.message, "error");
    }
     
    return Observable.throw(error || " server error ");
  }
}
