
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AppService } from '../app.service';
import { LoginService } from '../login/login.service';
import swal from 'sweetalert2';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { promptFunction } from 'app/shared/data/sweet-alerts';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';
// import 'rxjs/Rx';
//  import 'rxjs/add/operator/toPromise';




@Injectable()
export class AnalysisService {
  public profileId: any;
  //// public profileId1=[];
  public selContractID:any;
  public profileFormId: any;
  public profileFormData: any;
  public access_token: any;
  public access_token_asw: any;
  showBehaviour:boolean=false;

  public baseUrl:any;
  private headers = new Headers({ 'content-type': 'application/json' });
  private options = new RequestOptions({ headers: this.headers });
  //public productUrl = "http://10.20.30.6:6006/mff/api/products";
  //public mffSycBaseUrl="http://localhost:6007/mff/sync/api";
  public profileBaseUrl: any;
  ///public portNumberID="";
  constructor(private _http: Http, private commonservice: AppService, private loginService: LoginService) {
  this.baseUrl = this.loginService.urlNumber()+":"+this.loginService.portnumber();

    this.access_token = loginService.access_tokenMethod();
    // alert(loginService.portnumber());
    this.access_token_asw = loginService.accessTokenAsw();


  }
  profilePort() {
    // alert(this.loginService.portnumber())
    // alert(this.loginService.urlNumber())
    
   // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
     return this.profileBaseUrl=this.loginService.urlNumber()+":"+this.loginService.portnumber()+"/mff/api";
    
    // return this.profileBaseUrl="http://188.214.128.73:"+this.loginService.portnumber()+"/mff/api";
    // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
    // return this.profileBaseUrl="http://"+this.loginService.urlNumber()+":6060/mff/api";
   //  return this.profileBaseUrl = "http://localhost:6061/mff/api";
    //return this.profileBaseUrl="http://localhost:5151/MFFService";
  }





  onBoardStatus() {
    return this._http.get(this.profilePort() + '/onboardingStatusReport?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
     .catch(this.errorHandler);
  }
  personalInformation() {
    return this._http.get(this.profilePort() + "/personalInformationReport?access_token=" + this.access_token, this.options).map((response: Response) => response.json())
     .catch(this.errorHandler);
  }

  personalInformationPagination(pageNumber,noOfRecords) {
    return this._http.get(this.profilePort() + '/personalInformationReport?&numberOfRecords='+noOfRecords+'&pageNumber='+pageNumber+'&access_token=' + this.access_token, this.options).map((response: Response) => response.json())
     .catch(this.errorHandler);
  }

  // onBoardReportPagination(noOfRecords,pageNumber) {
  //   return this._http.get(this.profilePort() + '/onboardingStatusReport?&numberOfRecords='+noOfRecords+'&pageNumber='+pageNumber+'&access_token=' + this.access_token, this.options).map((response: Response) => response.json())
  //    .catch(this.errorHandler);
  // }

  onBoardReportPagination(noOfRecords,pageNumber,profileId) {
    return this._http.get(this.profilePort() + '/onboardingStatusReport?access_token=' + this.access_token+(noOfRecords?'&numberOfRecords='+noOfRecords:'')+(pageNumber?'&pageNumber='+pageNumber:'')+(profileId?'&profileIds='+profileId:'&profileIds=')).map((response: Response) => response.json())
     .catch(this.errorHandler);
  }

  getAllBranches() {
    return this._http.get(this.profilePort() + '/branches/hierarchy?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
     .catch(this.errorHandler);
  }

  // getEventsbyIED(){
  //   return this._http.get(this.commonservice.soltixURL+'5050/BusinessEventService/events?eventType=IED&access_token='+this.access_token_asw)
  // .map((res:Response) => res.json()).catch(this.errorHandler); 
  // }

  getEventsbyIED(){
    return this._http.get(this.loginService.BUSINESS_EVENT_URL+'/business/events?eventType=IED&access_token='+this.access_token)
  .map((res:Response) => res.json()).catch(this.errorHandler); 
  }

  getRepaymentBehaviour(contractID){
    return this._http.get(this.loginService.CONTRACT_URL+'/contracts/delinquency/reportBy/'+contractID+'?access_token='+this.access_token)
  .map((res:Response) => res.json()).catch(this.errorHandler); 
  }

  getRepaymentBehaviourWithDate(contractID,date){
    return this._http.get(this.loginService.CONTRACT_URL+'/contracts/delinquency/reportBy/'+contractID+'?date='+date+'&access_token='+this.access_token)
  .map((res:Response) => res.json()).catch(this.errorHandler); 
  }
  //uploadFiles

  getCreditRating(){
    return this._http.get(this.commonservice.validateURL+'/getcreditRating?access_token='+this.access_token)
  .map((res:Response) => res.json()).catch(this.errorHandler); 
  }
  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    console.log("errorMessage:"+JSON.stringify(errorMessage));
  
    if(errorMessage.status == 400){
      swal("Sorry!", errorMessage.message, "error");
    
    }
    else if(errorMessage.status == 404){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.status == 401){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.message=="Access is Denied" || errorMessage.message=="Access is denied"){
      swal("Sorry!", errorMessage.message, "error");
    }
     
    return Observable.throw(error || " server error ");
  }

}
